import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import Image from "../../components/ImageQuerys/RandomImages";
import {
  pizzaPosHero,
  pizzaPosSubHero,
  pizzaPosWhiteGlove,
  pizzaPosLargeFeatureA,
  pizzaBadges,
  autoPartsMultiSection,
  pizzaPosCTA,
  pizzaPosVideo,
  pizzaPosTestimonials,
  pizzaPosCar,
} from "../../data/pizza-data";
import {
  basecampSlides,
  heroSuccess,
} from "../../data/success-stories-data";

import heroBg from "../../images/hero-images/casual-dining-hero.png";

const NoVisualsCustom = loadable(() =>
  import("../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const Badges = loadable(() =>
  import("../../components/ConversionPath/Badges")
);

const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../components/VideoSection/VideoSection")
);
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../components/BusinessTypes/BusinessTypes")
);

const whiteGloveImport = "pizza-pos-white-glove.png";

const index = () => {
  return (
    <Layout>
      <SEO
        title="Pizza POS System | Online Ordering & Delivery Software System | SpotOn"
        description="Our end-to-end pizza shop POS system is made to scale your business and give you better data and insights. We offer one complete point of sale solution. Get a demo."
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/pizza-pos/"
        productSchemaSR
      />
      <BHero sectionData={pizzaPosHero} heroBg="pizza-pos-hero.png" />
      <div>
        <Image
          className="mx-auto my-[60px] max-w-[500px]"
          imageName="pizza-logos.png"
          objectFit="scale-down"
        />
      </div>
      <NoVisualsCustom sectionData={pizzaPosSubHero} simple />
      <WhiteGlove
        sectionData={pizzaPosWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={pizzaPosLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <Badges items={pizzaBadges} />
      <LargeFeatures
        sectionData={autoPartsMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={pizzaPosCTA} />
      <SuccessStories sectionData={heroSuccess} slides={basecampSlides} />
      <VideoSection sectionData={pizzaPosVideo} />
      <TestmonialReviews sectionData={pizzaPosTestimonials} />
      <BusinessTypes sectionData={pizzaPosCar} />
    </Layout>
  );
};

export default index;
